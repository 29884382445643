import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import { isMobile } from './utils';

import { defineComponent, onMounted } from 'vue';
export default defineComponent({
  setup() {
    onMounted(() => {
      const App = document.getElementById('app');
      if (isMobile()) App.className = 'isMobile';
    });
    return () => _createVNode(_resolveComponent("router-view"), null, null);
  }
});