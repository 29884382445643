import App from './App';
import router from './router';
import store from './store';
import '@/assets/style/base.scss';
import '@/assets/style/font.scss';

import { createApp } from 'vue';
const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');