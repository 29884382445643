const routes = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/test',
  name: 'Test',
  component: () => import( /* webpackChunkName: "Test" */'@/views/Test')
}, {
  path: '/login',
  name: 'Login',
  component: () => import( /* webpackChunkName: "Login" */'@/views/Login')
}, {
  path: '/monitor',
  name: 'monitor',
  component: () => import( /* webpackChunkName: "monitor" */'@/views/WindPower')
}, {
  path: '/monitor-mobile',
  name: 'monitorMobile',
  component: () => import( /* webpackChunkName: "monitorMobile" */'@/views/WindPowerMobile')
}
// {
//   path: '/wind-power-mobile',
//   name: 'WindPowerMobile',
//   component: () =>
//     import(
//       /* webpackChunkName: "WindPowerMobile" */ '@/views/WindPowerMobile'
//     ),
//   children: [
//     {
//       path: '/wind-power-mobile/home',
//       meta: {
//         name: '首页'
//       },
//       component: () =>
//         import(
//           /* webpackChunkName: "WindPowerMobileHome" */ '@/views/WindPowerMobile/Home'
//         )
//     },
//     {
//       path: '/wind-power-mobile/warning',
//       meta: {
//         name: '预警提醒'
//       },
//       component: () =>
//         import(
//           /* webpackChunkName: "WindPowerMobileWarning" */ '@/views/WindPowerMobile/Warning'
//         )
//     },
//     {
//       path: '/wind-power-mobile/voiceprint',
//       meta: {
//         name: '声纹采集'
//       },
//       component: () =>
//         import(
//           /* webpackChunkName: "WindPowerMobileVoiceprint" */ '@/views/WindPowerMobile/Voiceprint'
//         )
//     },
//     {
//       path: '/wind-power-mobile/sensor',
//       meta: {
//         name: '传感分析'
//       },
//       component: () =>
//         import(
//           /* webpackChunkName: "WindPowerMobileSensor" */ '@/views/WindPowerMobile/Sensor'
//         )
//     },
//     {
//       path: '/wind-power-mobile/me',
//       meta: {
//         name: '我的'
//       },
//       component: () =>
//         import(
//           /* webpackChunkName: "WindPowerMobileMe" */ '@/views/WindPowerMobile/Me'
//         )
//     }
//   ]
// }
];

export default routes;